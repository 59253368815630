// {} 此符号为具名格式化参数  勿动
// <> 此符号为html标签 勿动
export default {
   // 首页
   index: {
      mainTitle: 'CCE Cash - Fast Cross-chain Exchange',
      deepTitle: 'Deep Exchange',
      deepHint: 'Experience the DEEP EXCHANGE service for safer exchange now',
      deepHintB: 'Experience DEEP EXCHANGE Now',
      hour: 'hour',
      hours: 'hours',
      hourText: 'Exchange Duration',
      feeText: 'Exchange Fee',
      feeHint: "Fee Included",
      addressHint: "Invalid address",
      percentHint: "The sum of all currencies ratios does not equal 100%",
      recvtHint: "You will receive:",
      add_right_coin: '+ Add New',
      del_right_coin: '- Del Last',
      safe1: "Very Safe !",
      safe2: "Extremely Safe !!",
      safe3: "The Safest !!!",
      faq0NameDeep: "What is DEEP EXCHANGE service?",
      faq0ContentDeep: 'The deep exchange service will conduct cross-chain exchanges continuously within the specified service period. Eventually, you will receive more than two types of currencies. This method improves the security and confidentiality of the currency after exchange.',
      faq1NameDeep: "Why does the longer the exchange duration, the higher the handling fee?",
      faq1ContentDeep: "Longer exchange time means more cross-chain exchange processes (you do not need to participate in these processes). Each cross-chain process will generate corresponding handling fees, so the longer the exchange time, the higher the handling fees.",
      faq2NameDeep: "How to ensure the security of deep exchange?",
      faq2ContentDeep: 'The deep exchange service will use multiple currencies and a large number of currency addresses to continuously conduct cross-chain exchanges, and the amount of each cross-chain exchange will also be split into irregular small amounts. For safer exchange, it is recommended to choose a longer exchange duration.',
      fee: "Fee",
      SEND: 'SEND',
      RECEIVE: 'RECEIVE',
      receive_wallet_label: "Your {sortName} address",
      fixed: 'Fixed rate',
      float: 'Float rate',
	  fee_hint: 'Fees depend on network status and potential price fluctuations',
      exchange_submit: 'Exchange now',
      advantages0Name: 'Save time',
      advantages0Descr: 'Maximum exchange speed due to the full automation',
      advantages1Name: 'Save money',
      advantages1Descr: 'Best exchange rates and minimum commissions',
      advantages2Name: 'Anonymity',
      advantages2Descr: 'The exchange address is randomly generated, anonymous exchange does not require login, and the entire process cannot be traced',
      faq0Name: "How the exchange works",
      faq0Content: 'The exchange tool automatically connects to multiple cryptocurrency exchanges, including official cooperative exchanges such as Binance, OKX, Bitfinex, etc. We will choose the best price and exchange rate on the market for exchange at any time.',
      faq1Name: "Why can I trust you?",
      faq1Content: "No registration and no need to share your personal details. We don't hold your funds, all exchanges take place instantly in fully  automatic mode.",
      faq2Name: "How fast is the exchange?",
      faq2Content: 'Exchange only takes 2 to 20 minutes, and most orders take just a few minutes to complete. If the transaction volume is high, it may take longer, depending on your transaction size and block size. With our innovative trading algorithm, we have the highest exchange speed on the market.'
   },
   // 订单
   order: {
      coinHeadSend: 'You send',
      coinHeadReceive: 'You receive',
      orderInfoId: 'Order ID',
      orderInfoRemaining: 'Time remaining',
      orderInfoStatus: 'Order status',
      expired: 'Order expired',
      exchange: 'Received',
      done: 'Completed',
      orderInfoType: 'Order type',
      orderInfoCreation: 'Creation Time',
      orderInfoReceived: 'Received Time',
      orderInfoCompleted: 'Completed Time',
      amountLeft: 'Send',
      amountRight: 'to the address:',
      amountRight1:'To the address:',
      attention: 'The exchange rate will be fixed after receiving <b>{number}</b> network confirmations.',
      destination: 'Receiving address',
      detailsH3: 'Transaction info',
      blockchain: 'View on explorer',
      Received: 'Received Time',
      Block: 'Block Time',
      Confirmations: 'Confirmations',
      Amount: 'Amount',
      Fee: 'Fee',
      qrcode: 'Address',
      doneLeft: 'Your',
      doneRight: 'was sent',
      doneDetailsLeft: 'Accepted transaction info',
      doneDetailsright: 'Sent transaction info',
      timeline0: 'Awaiting deposit',
      timeline1: 'Awaiting confirmations',
      timeline2: 'Perform exchange',
      timeline3: 'Done',
      timeLineRefunded: 'Refunded',
      foremergency: 'Waiting for user response',
      knownTitle: 'What do you need to know?',
      known1: 'You only need <strong>{number}</strong> confirmation for the exchange',
      known2: 'We use segwit Bitcoin  addresses for faster and cheaper transactions, if your  wallet does not support this type of address, contact technical support to change the address',
      known3: 'We do not support sending and receiving Ethereum using smart contracts',
      known4: 'If the amount of the transaction you sent differs from the initial amount specified in the order with a float rate, the order will be automatically recalculated.',
      known5: 'If your payment time exceeds 30 minutes and the order has expired, your payment transaction will not take effect at this time, please contact customer service to return your relevant funds.',
      notyfyTitle:'Order status notifications',
      notyfyContent:'Enter your email if you want to receive notifications about changes in the status of this order',
      notyfyPlaceholder:'Type your email address',
      notyfybtn:'Confirm'
   },
   // 菜单
   menus: {
      Home: 'Home',
      Deep: 'Deep Exchange',
      About: 'Tutorial', 
      Blog: 'Blog',
      FAQ: 'FAQ',
      API: 'API',
      Support: 'Support',
      'SignIn': 'Sign in',
      'SignUp': 'Sign up'
   },
   // Support
   support:{
      title:'We are happy to help you sort out any issues 24 hours a day, 7 days  a week.',
      list1Title:'Live chat',
      list1Content:'Start a chat with us now',
      list2Title:'Telegram',
      list2Content:'Estimated reply time: 10 minutes',
   },
   footer: {
      Popular: "Popular",
      CRYPTOMIXER: 'CCE Cash',
      TermsOfService:'Terms of service',
      PrivacyPolicy:'Privacy Policy'
   }
}