import {
    get,
    post
} from './axios';

// 登录
export const login = (data) => post('/pub/login', data)

/****************************** 首页 ******************************/ 

// 获取可兑换货币列表
export const getCurrencyList = (data) => get('currency_list', data);

// 获取货币兑换汇率
export const getExchangePrice = (data) => post('exchange_price', data);

export const getExchangePriceDeep = (data) => post('exchange_price_deep', data);

// 验证收款地址正确性
export const validAddress = (data) => post('exchange_address_info', data);

// 生成货币兑换订单
export const getExchangeMake = (data) => post('exchange_make', data);

export const getExchangeMakeDeep = (data) => post('exchange_make_deep', data);

// 设置订单邮箱
export const getExchangeAddressInfo = (data) => post('order_set_email', data);

// 获取页面信息
export const getPageContent = (data) => get('page_content', data);

export const getNotice = (data) => get('notice', data);