// 繁体中文 
// {} 此符号为具名格式化参数  勿动
// <> 此符号为html标签 勿动
export default {
    // 首页
    index: {
        mainTitle: 'CCE Cash - Удобный сервис межсетевого обмена',
        deepTitle: 'Глубокий обмен',
        deepHint: 'Испытайте более безопасные и углубленные услуги обмена прямо сейчас',
        deepHintB: 'Испытайте углубленную услугу обмена прямо сейчас',
        hour: 'Час',
        hours: 'Час',
        hourText: 'Время погашения',
        feeText: 'Exchange Fee',
        feeHint: "плата за обработку",
        addressHint: "Неправильный адрес",
        percentHint: "Сумма всех пропорций валюты чека должна составлять 100%",
        recvtHint: "вы получите:",
        add_right_coin: '+ Новый',
        del_right_coin: '- удалить',
        safe1: "",
        safe2: "",
        safe3: "",
        fee: "плата за обработку",
        faq0NameDeep: "Что такое сервис глубокого обмена?",
        faq0ContentDeep: 'Служба глубокого обмена будет проводить межсетевые обмены непрерывно в течение указанного периода обслуживания. В конечном итоге вы получите более двух типов валют. Этот метод повышает безопасность и конфиденциальность валюты после обмена.',
        faq1NameDeep: "Почему чем дольше время обмена, тем выше комиссия за обработку??",
        faq1ContentDeep: "Более продолжительное время обмена означает больше процессов межсетевого обмена (вам не нужно участвовать в этих процессах).Каждый межсетевой процесс будет генерировать соответствующую комиссию за обработку, поэтому чем дольше время обмена, тем выше комиссия за обработку.",
        faq2NameDeep: "Как обеспечить безопасность глубокого обмена?",
        faq2ContentDeep: 'Служба глубокого обмена будет использовать несколько валют и большое количество валютных адресов для непрерывного проведения межсетевых обменов, при этом сумма каждого межсетевого обмена также будет разделена на нерегулярные небольшие суммы. Для более безопасного обмена рекомендуется выбрать более длительный период обмена.',
        SEND: 'платить',
        RECEIVE: 'Коллекция',
        receive_wallet_label: "твой {sortName} адрес",
        fixed: 'фиксированная ставка',
        float: 'плавающая ставка',
        fee_hint: 'Fee（комиссия за обмен）Зависит от уровня перегрузки сети блокчейна и сложности логики множественного обмена безопасностью.',
        exchange_submit: 'Начать активировать',
        advantages0Name: 'Быстрый',
        advantages0Descr: 'Простой и полностью автоматический процесс межсетевого обмена обеспечивает максимальную скорость обмена.',
        advantages1Name: 'Экономить деньги',
        advantages1Descr: 'Автоматически сопоставлять последнюю рыночную цену и самую низкую комиссию за обработку',
        advantages2Name: 'скрытый',
        advantages2Descr: 'Адрес погашения генерируется случайным образом, анонимное погашение не требует входа в систему, и весь процесс невозможно отследить.',
        faq0Name: "Как происходит процесс погашения?",
        faq0Content: 'Инструмент обмена автоматически подключается к нескольким биржам криптовалют, включая официальные партнерские биржи, такие как Binance, OKX,Bitfinex. Для обмена мы в любой момент подберем наиболее выгодную цену и курс на рынке.。',
        faq1Name: "Почему нам доверяют?",
        faq1Content: "Платформа была создана командой опытных разработчиков блокчейнов и представляет собой полностью безопасный сервис обмена. Нам вообще не требуется вход в систему, что позволяет нашим клиентам избежать кражи личных данных или финансовых средств. Мы предлагаем лучшие цены на рынке, используя надежную торговую платформу криптовалют.。",
        faq2Name: "Как быстро происходит обмен??",
        faq2Content: 'Выкуп занимает всего от 2 до 20 минут, а большинство заказов выполняется всего за несколько минут. Если объем транзакции велик, это может занять больше времени в зависимости от размера вашей транзакции и емкости блока. Благодаря нашим инновационным торговым алгоритмам мы обеспечиваем самую высокую скорость обмена на рынке.'
    },
    // 订单
    order: {
       coinHeadSend: 'ты хочешь отправить',
       coinHeadReceive: 'вы получите',
       orderInfoId: 'номер заказа',
       orderInfoRemaining: 'оставшееся время',
       orderInfoStatus: 'Статус заказа',
       expired: 'Тайм-аут заказа',
       exchange: 'Уже оплачено',
       done: 'Заказ выполнен',
       orderInfoType: 'Тип заказа',
       orderInfoCreation: 'время создания',
       orderInfoReceived: 'Время оплаты пользователя',
       orderInfoCompleted: 'Время оплаты пользователя',
       amountLeft: 'отправлять',
       amountRight: 'Перейти по этому адресу:',
       attention: 'Комиссия за обменную транзакцию будет зафиксирована после подтверждения <b>{number}</b> узла блокчейна.',
       destination: 'Платежный адрес',
       detailsH3: 'Торговая информация',
       blockchain: 'Запрос в блокчейне',
       Received: 'Время получать оплату',
       Block: 'Блокировать время упаковки',
       Confirmations: 'Количество подтверждений',
       Amount: 'Количество',
       Fee: 'Комиссия за внутрисетевую обработку',
       qrcode: 'Адрес QR-кода',
       doneLeft: 'твой',
       doneRight: 'был отправлен',
       doneDetailsLeft: 'Детали платежной транзакции пользователя',
       doneDetailsright: 'Детали платежной транзакции пользователя',
       timeline0: 'Ожидание оплаты пользователем',
       timeline1: 'Подождите достаточного подтверждения',
       timeline2: 'Выполнить обмен',
       timeline3: 'Заканчивать',
       timeLineRefunded: 'Refunded',
       foremergency: 'Waiting for user response',
       knownTitle: 'Что тебе нужно знать',
       known1: 'Для вашей платежной транзакции требуется только <strong>{number}</strong> подтверждение узла блокчейна',
       known2: 'We use segwit Bitcoin  addresses for faster and cheaper transactions, if your  wallet does not support this type of address, contact technical support to change the address',
       known3: 'We do not support sending and receiving Ethereum using smart contracts',
       known4: 'Если сумма, которую вы платите, отличается от суммы в заказе, заказ автоматически пересчитает сумму валюты, которую вы получите, на основе текущего обменного курса и комиссий за обработку.',
       known5: 'Если время оплаты превышает 30 минут и время ожидания статуса заказа истекло, ваша платежная транзакция в этот момент не вступит в силу. Пожалуйста, свяжитесь со службой поддержки клиентов, чтобы вернуть соответствующие средства.',
       notyfyTitle:'Уведомление о статусе заказа',
       notyfyContent:'Вы можете ввести свой адрес электронной почты, чтобы получить последний статус заказа.',
       notyfyPlaceholder:'Введите адрес электронной почты',
       notyfybtn:'подтверждать'
    },
    // 菜单
    menus: {
       Home: 'Домашняя страница',
       Deep: 'Глубокий обмен',
       About: 'о',
       Blog: 'Blog',
       FAQ: 'FAQ',
       API: 'API',
       Support: 'помощь',
       'SignIn': 'Sign in',
       'SignUp': 'Sign up'
    },
    // Support
    support:{
       title:'Мы онлайн 24/7 и приветствуем запросы.',
       list1Title:'Интернет Сервис',
       list1Content:'Нажмите, чтобы начать онлайн-консультацию',
       list2Title:'Telegram',
       list2Content:'Нажмите, чтобы добавить Telegram，Или найдите номер TG：Mixing_Cash',
    },
    footer: {
       Popular: "Популярные биржи",
	   CRYPTOMIXER: 'CCE Cash',
	   TermsOfService:'Условия использования',
	   PrivacyPolicy:'Заявление о конфиденциальности'
    }
 }