let util = {

};
//日期格式化
Date.prototype.Format = function (fmt) {
	var o = {
		"M+": this.getMonth() + 1, //月份
		"d+": this.getDate(), //日
		"h+": this.getHours(), //小时
		"m+": this.getMinutes(), //分
		"s+": this.getSeconds(), //秒
		"q+": Math.floor((this.getMonth() + 3) / 3), //季度
		"S": this.getMilliseconds() //毫秒
	};
	if (/(y+)/.test(fmt))
		fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
	for (var k in o)
		if (new RegExp("(" + k + ")").test(fmt))
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
	return fmt;
};
util.format = function (times, pattern) {
	var d = new Date(times).Format("yyyy-MM-dd hh:mm:ss");
	if (pattern) {
		d = new Date(times).Format(pattern);
	}
	return d.toLocaleString();
}
/* -----------------处理筛选掉空值的对象属性---------------- */
util.dealObjectValue = function (obj) {
	var param = {};
	if (obj === null || obj === undefined || obj === "") return param;
	for (var key in obj) {
		if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
			param[key] = obj[key];
		}
	}
	return param;
}

/* -----------------递归生成tree型结构数据---------------- */
util.toTree = function (treeData) {
	// 删除 所有 children,以防止多次调用
	treeData.forEach(function (item) {
		delete item.children;
	});
	// 将数据存储为 以 id 为 KEY 的 map 索引数据列
	var map = {};
	treeData.forEach(function (item) {
		map[item.id] = item;
	});
	var val = [];
	treeData.forEach(function (item) {
		// 以当前遍历项，的pid,去map对象中找到索引的id
		var parent = map[item.parentId];
		// 好绕啊，如果找到索引，那么说明此项不在顶级当中,那么需要把此项添加到，他对应的父级中
		if (parent) {
			(parent.children || (parent.children = [])).push(item);
		} else {
			//如果没有在map中找到对应的索引ID,那么直接把 当前的item添加到 val结果集中，作为顶级
			val.push(item);
		}
	});
	return val;
}
/* -----------------去除字符串所有空格---------------- */
// // 功能: 1)去除字符串前后所有空格
// 2)去除字符串中所有空格(包括中间空格,需要设置第2个参数为:g)
util.clearSpace = function (str, is_global) {
	var result;
	result = str.replace(/(^\s+)|(\s+$)/g, "");
	if (is_global.toLowerCase() == "g")
		result = result.replace(/\s/g, "");
	return result;
}

/* -----------------计算两个时间之间的间隔时间--------------- */
util.DateDifference = function DateDifference(faultDate, completeTime, type) {
	// type为2精确到分秒，为3精确到时分秒
	var stime = new Date(faultDate).getTime();
	var etime = new Date(completeTime).getTime();
	var usedTime = etime - stime; //两个时间戳相差的毫秒数
	// var days = Math.floor(usedTime / (24 * 3600 * 1000));
	//计算出小时数
	var leave1 = usedTime % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
	var hours = Math.floor(leave1 / (3600 * 1000));
	//计算相差分钟数
	var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
	var minutes = Math.floor(leave2 / (60 * 1000));
	var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
	var seconds = Math.floor(leave3 / 1000);
	if (type == 2) {
		return minutes + "分" + seconds + '秒'
	} else if (type == 3) {
		return hours + "时" + minutes + "分" + seconds + '秒'
	}
}
// 将base64转换成file对象
util.dataURLtoFile = function (dataurl, filename = "file") {
	let arr = dataurl.split(",");
	let mime = arr[0].match(/:(.*?);/)[1];
	let suffix = mime.split("/")[1];
	let bstr = atob(arr[1]);
	let n = bstr.length;
	let u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], `${filename}.${suffix}`, {
		type: mime
	});
}

function imageToCanvas(src, cb) {
	var img = new Image();
	img.src = src;
	img.onload = function () {
		var that = this;
		// 默认按比例压缩
		var w = that.width,
			h = that.height,
			scale = w / h;
		w = 200 || w;
		h = (w / scale);
		//生成canvas
		var canvas = document.createElement('canvas');
		var ctx = canvas.getContext('2d');
		// 创建属性节点
		var anw = document.createAttribute("width");
		anw.nodeValue = w;
		var anh = document.createAttribute("height");
		anh.nodeValue = h;
		canvas.setAttributeNode(anw);
		canvas.setAttributeNode(anh);
		ctx.drawImage(that, 0, 0, w, h);
		cb(canvas)
	}
}

function dataURLToBlob(dataurl) {
	var arr = dataurl.split(',')
	var mime = arr[0].match(/:(.*?);/)[1]
	var bstr = atob(arr[1])
	var n = bstr.length
	var u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	return new Blob([u8arr], {
		type: mime
	})
}

function canvasToDataURL(canvas, format, quality) {
	// 默认图片质量为0.7
	return canvas.toDataURL(format || 'image/jpeg', quality || 0.7)
}
//image 转 Blob
util.imageToBlob = function (src, cb) {
	imageToCanvas(src, function (canvas) {
		cb(dataURLToBlob(canvasToDataURL(canvas)))
	})
}
/* -----------------获取guidKey---------------- */
util.getGUID = function () {
	var s = [];
	var hexDigits = "0123456789abcdef";
	for (var i = 0; i < 36; i++) {
		s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
	}
	s[14] = "4";
	s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
	s[8] = s[13] = s[18] = s[23] = "-";
	var guid = s.join("");
	return guid;
}
/* -----------------获取图片类型---------------- */
util.getImageType = function (name) {
	let index = name.lastIndexOf('.');
	let type = name.substr(index + 1);
	return type;
}
/* -----------------获取是否小于当前时间的判断---------------- */
util.dealDisabledDate = function (time) {
	return time.getTime() < Date.now() - 8.64e7;
}
/* -----------------计算两个整数的百分比值---------------- */
util.GetPercent = function (num, total) {
	num = parseFloat(num);
	total = parseFloat(total);
	if (isNaN(num) || isNaN(total)) {
		return "-";
	}
	return total <= 0 ? "0%" : (Math.round(num / total * 10000) / 100.00 + "%");
}
/* -----------------比对两个对象是否完全相同---------------- */
util.isObjectValueEqualNew = function (a, b) {
	var aProps = Object.keys(a);
	var bProps = Object.keys(b);
	if (aProps.length != bProps.length) {  //先判断两个对象的属性个数是否相同
		return false;
	}
	for (var i = 0; i < aProps.length; i++) {
		var propName = aProps[i]
		var propA = a[propName]
		var propB = b[propName]
		if ((typeof (propA) === 'object')) {   //如果某一个属性值也是对象，进行递归
			if (util.isObjectValueEqualNew(propA, propB)) {
				return true
			} else {
				return false
			}
		} else if (propA !== propB) {
			return false
		}
	}
	return true
}

util.getUrlKey = function (name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
}
export default util;