// 繁体中文 
// {} 此符号为具名格式化参数  勿动
// <> 此符号为html标签 勿动
export default {
    // 首页
    index: {
        mainTitle: 'CCE Cash - 便捷跨鏈兌換服務',
        deepTitle: '深度兌換',
        deepHint: '立即體驗更安全的深度兌換服務',
        deepHintB: '立即體驗深度兌換服務',
        hour: '小時',
        hours: '小時',
        hourText: '兌換時長',
        feeText: 'Exchange Fee',
        feeHint: "手續費",
        addressHint: "地址有誤",
        percentHint: "所有收款貨幣比重之和需為100%",
        recvtHint: "您會收到:",
        add_right_coin: '+ 新增貨幣',
        del_right_coin: '- 刪除最後',
        safe1: "非常安全 !",
        safe2: "極其安全 !!",
        safe3: "最安全 !!!",
        fee: "手續費",
        faq0NameDeep: "什麽是深度兌換服務?",
        faq0ContentDeep: '深度兑换服务會在指定的服務時長内不間斷的進行跨鏈兌換，最終您會收到兩種以上種類的貨幣，以此方法提高兌換后的貨幣安全性和隱匿性。',
        faq1NameDeep: "為社麽兌換時間越長手續費越高?",
        faq1ContentDeep: "更長的兌換時間意味著更多次的跨鏈兌換流程(這些流程您無需參與)，每次執行跨鏈流程都會產生相應的手續費, 因此兌換時間越長手續費越高。",
        faq2NameDeep: "怎麽確保深度兌換的安全性?",
        faq2ContentDeep: '深度兌換服務會使用多種貨幣以及大量的貨幣地址不斷地進行跨鏈兌換，每次跨鏈兌換金額也會被拆分成無規律的小金額。爲了更安全的兌換，建議選擇更長的兌換時長。',
        SEND: '支付',
        RECEIVE: '收款',
        receive_wallet_label: "您的 {sortName} 地址",
        fixed: '固定費率',
        float: '浮動費率',
        fee_hint: '兌換手續費取決於區塊鏈網狀況以及潛在的價格波動',
        exchange_submit: '開始兌換',
        advantages0Name: '快捷',
        advantages0Descr: '簡易且全自動跨鏈兌換流程帶來最快的兌換速度',
        advantages1Name: '省錢',
        advantages1Descr: '自動匹配市場最新價格以及最低手續費率',
        advantages2Name: '隱蔽',
        advantages2Descr: '兌換地址隨機生成，匿名兌換不需要登錄，整個流程無法被追蹤溯源',
        faq0Name: "兌換流程如何運作的?",
        faq0Content: '兌換工具自動對接多個加密貨幣交易所，包括幣安、歐易、Bitfinex等官方合作交易所。我們在任何時刻均會選擇市場上最優惠的價格和匯率進行兌換。',
        faq1Name: "爲什麽信任我們?",
        faq1Content: "平臺由經驗豐富的區塊鏈開發人員團隊創建，是一個完全安全的兌換服務。我們完全不需要註冊登錄，這使我們的客戶避免身份識別或財務盜竊。我們使用可靠的加密貨幣交易平臺提供市場上最優惠的價格。",
        faq2Name: "兌換速度有多快?",
        faq2Content: '兌換只需要2到20分鐘，并且大多數訂單只需幾分鐘即可完成。如果交易量很大，可能需要更長的時間，具體取決於您的交易規模和區塊的容量。憑借我們創新的的交易算法，我們擁有市場上最高的交換速度。'
    },
    // 订单
    order: {
       coinHeadSend: '您要發送',
       coinHeadReceive: '您會收到',
       orderInfoId: '訂單號',
       orderInfoRemaining: '剩餘時間',
       orderInfoStatus: '訂單狀態',
       expired: '訂單超時',
       exchange: '已付款',
       done: '訂單完成',
       orderInfoType: '訂單類型',
       orderInfoCreation: '創建時間',
       orderInfoReceived: '用戶付款時間',
       orderInfoCompleted: '用戶收款時間',
       amountLeft: '發送',
       amountRight: '到此地址:',
       amountRight1: '到此地址:',
       attention: '兌換交易手續費將在 <b>{number}</b> 次區塊鏈節點確認后固定。',
       destination: '收款地址',
       detailsH3: '交易信息',
       blockchain: '區塊鏈上查詢',
       Received: '收到付款時間',
       Block: '區塊打包時間',
       Confirmations: '確認數',
       Amount: '金額',
       Fee: '鏈上手續費',
       qrcode: '二維碼地址',
       doneLeft: '您的',
       doneRight: '已被發送',
       doneDetailsLeft: '用戶付款交易詳情',
       doneDetailsright: '用戶收款交易詳情',
       timeline0: '等待用戶付款',
       timeline1: '等待足夠確認',
       timeline2: '執行兌換',
       timeline3: '完成',
       timeLineRefunded: 'Refunded',
       foremergency: 'Waiting for user response',
       knownTitle: '您需要知道的事項',
       known1: '您的付款交易只需要 <strong>{number}</strong> 次區塊鏈節點確認。',
       known2: 'We use segwit Bitcoin  addresses for faster and cheaper transactions, if your  wallet does not support this type of address, contact technical support to change the address',
       known3: 'We do not support sending and receiving Ethereum using smart contracts',
       known4: '如果您付款的金額與訂單中的金額不同，訂單將會自動根據當前價格匯率和手續費重新計算您將得到的貨幣數量。',
       known5: '如果您的付款時間超過了30分鐘且訂單狀態已經超時，此時您的付款交易將不會生效，請聯係客服退回您的相關資金。',
       notyfyTitle:'訂單狀態通知',
       notyfyContent:'您可以請輸入郵箱地址，以獲取最新的訂單狀態。',
       notyfyPlaceholder:'輸入您的郵箱',
       notyfybtn:'確認'
    },
    // 菜单
    menus: {
       Home: '主頁',
       Deep: '深度兌換',
       About: '教程',
       Blog: 'Blog',
       FAQ: 'FAQ',
       API: 'API',
       Support: '幫助',
       'SignIn': 'Sign in',
       'SignUp': 'Sign up'
    },
    // Support
    support:{
       title:'我們7x24小時在綫，歡迎咨詢。',
       list1Title:'在綫客服',
       list1Content:'點擊開始在綫咨詢',
       list2Title:'Telegram',
       list2Content:'點擊添加小飛機，或搜索tg號：cce_cash',
    },
    footer: {
       Popular: "熱門兌換",
	   CRYPTOMIXER: 'CCE Cash',
	   TermsOfService:'服务条款',
	   PrivacyPolicy:'隐私声明'
    }
 }