export default {
    index:{
        mainTitle:'闪电加密货币兑换',
        SEND:'发送',
        RECEIVE:'接收',
        currencies: '主流货币'
     },
     menus:{
        About:'关于',
        Blog:'博客',
        FAQ:'问答',
        API:'说明',
        Support:'支持',
        'SignIn':'登录',
        'SignUp' : '注册'
     }
  }