// 法语
// {} 此符号为具名格式化参数  勿动
// <> 此符号为html标签 勿动
export default {
    // 首页
    index: {
       mainTitle: 'Lightning cryptocurrency exchange',
       SEND: 'SEND',
       RECEIVE: 'RECEIVE',
       receive_wallet_label: "Your {sortName} address",
       fixed: 'Fixed rate',
       float: 'Float rate',
       exchange_submit: 'Exchange now',
       advantages0Name: 'Save time',
       advantages0Descr: 'Maximum exchange speed due to the full automation',
       advantages1Name: 'Save money',
       advantages1Descr: 'Best exchange rates and minimum commissions',
       advantages2Name: 'Make an exchange',
       advantages2Descr: 'Pick the right strategy and make favourable trades',
       faq0Name: "How can I track my order?",
       faq0Content: 'In three ways: by email (if you leave your email address), on our website (if your browser accepts cookies) or by viewing the transactions in the blockchain by the links from your order.',
       faq1Name: "Why can I trust you?",
       faq1Content: "No registration and no need to share your personal details. We don't hold your funds, all exchanges take place instantly in fully  automatic mode.",
       faq2Name: "Do you have hidden fees?",
       faq2Content: 'Honesty is our main priority, so we commit to full transparency and make all the fees clear:'
    },
    // 订单
    order: {
       coinHeadSend: 'You send',
       coinHeadReceive: 'You receive',
       orderInfoId: 'Order ID',
       orderInfoRemaining: 'Time remaining',
       orderInfoStatus: 'Order status',
       expired: 'Order expired',
       exchange: 'Received',
       done: 'Completed',
       orderInfoType: 'Order type',
       orderInfoCreation: 'Creation Time',
       orderInfoReceived: 'Received Time',
       orderInfoCompleted: 'Completed Time',
       amountLeft: 'Send',
       amountRight: 'to the address:',
       attention: 'The exchange rate will be fixed after receiving <b>{number}</b> network confirmations.',
       destination: 'Receiving address',
       detailsH3: 'Transaction info',
       blockchain: 'View on the blockchain',
       Received: 'Received Time',
       Block: 'Block Time',
       Confirmations: 'Confirmations',
       Amount: 'Amount',
       Fee: 'Fee',
       qrcode: 'Address',
       doneLeft: 'Your',
       doneRight: 'was sent',
       doneDetailsLeft: 'Accepted transaction info',
       doneDetailsright: 'Sent transaction info',
       timeline0: 'Awaiting deposit',
       timeline1: 'Awaiting confirmations',
       timeline2: 'Perform exchange',
       timeline3: 'Done',
       timeLineRefunded: 'Refunded',
       foremergency: 'Waiting for user response',
       knownTitle: 'What do you need to know?',
       known1: 'You only need <strong>{number}</strong> confirmation of the Bitcoin blockchain for the exchange',
       known2: 'We use segwit Bitcoin  addresses for faster and cheaper transactions, if your  wallet does not support this type of address, contact technical support to change the address',
       known3: 'We do not support sending and receiving Ethereum using smart contracts',
       known4: 'If the amount of the transaction you sent differs from the initial amount specified in the order with a float rate, the order will be automatically recalculated.',
       known5: 'If your transaction is   received after the expiration of the order, but within 24      hours, then this transaction will be automatically displayed in the order. You will be able to continue the      order yourself or make a refund.',
       notyfyTitle:'Order status notifications',
       notyfyContent:'Enter your email if you want to receive notifications about changes in the status of this order',
       notyfyPlaceholder:'Type your email address',
       notyfybtn:'Confirm'
    },
    // 菜单
    menus: {
       About: 'About',
       Blog: 'Blog',
       FAQ: 'FAQ',
       API: 'API',
       Support: 'Support',
       'SignIn': 'Sign in',
       'SignUp': 'Sign up'
    },
    // Support
    support:{
       title:'We are happy to help you sort out any issues 24 hours a day, 7 days  a week.',
       list1Title:'Live chat',
       list1Content:'Start a chat with us now',
       list2Title:'Telegram',
       list2Content:'Estimated reply time: 10 minutes',
    },
    footer: {
       Popular: "Popular"
    }
 }