<template>
  <div id="app">
    <transition>
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  destroyed() {},
};
</script>

<style lang="less">
@import "./assets/css/svg_min.css";
@import "./assets/css/base.less";
#app,
.main-page {
  min-height: 100vh;
}
#app {
  background-color: #070d32;
  position: relative;
}
.main-page {
  display: flex;
  flex-direction: column;
}

.gradient-bg {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  // background: linear-gradient(180deg, rgba(8, 14, 53, 0) 0%, #070D32 50%) !important;
  &::after {
    content: "";
    display: block;
    background: radial-gradient(
      43.27% 37.28% at 50.52% 49.22%,
      #2d5495 0%,
      #070d32 100%
    ) !important;
    width: 100%;
    max-width: 1800px;
    height: 960px;
  }
}

@media screen and (max-width: 740px) {
  .gradient-bg {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    // background: linear-gradient(180deg, rgba(8, 14, 53, 0) 0%, #070D32 50%) !important;
    &::after {
      content: "";
      display: block;
      background: linear-gradient(
        180deg,
        #070d32 0%,
        #2d5495 34%,
        #070d3200 70%
      ) !important;
      width: 100%;
      max-width: 1800px;
      height: 960px;
    }
  }
}
.my-header .my-link .active::after {
  width: 24px !important;
  height: 3px !important;
}
@media only screen and (max-width: 740px) {
  .main-page header#header {
    background-color: #070d32 !important;
  }
}
</style>
