
import enLocale from './en'
import zhLocale from './zh'
import zhCnLocale from './zh_cn'
import deLocale from './de'
import esLocale from './es'
import frLocale from './fr'
import ptLocale from './pt'
import ruLocale from './ru'
const messages = {
    // 简体
    'zh-CN': zhCnLocale,
    // 英文
    'en-US': enLocale,
    // 繁体
    'zh' : zhLocale,
    de: deLocale,
    es: esLocale,
    fr: frLocale,
    pt: ptLocale,
    ru: ruLocale
};

export default messages;