import VueRouter from "vue-router";
import Nofound from "./pages/404.vue";
const localRouters = [
  {
    path: "/",
    meta: {
      title: "CCE Cash",
      allowBack: false,
    },
    redirect: "/main/home",
  },
  {
    path: "/main",
    name: "main",
    meta: {
      title: "CCE Cash",
      allowBack: false,
    },
    component: (resolve) => require(["./pages/main/main.vue"], resolve),
    children: [
      {
        path: "home",
        name: "home",
        meta: {
          title: "CCE Cash",
          allowBack: false,
        },
        component: (resolve) =>
          require(["./pages/main/home/home.vue"], resolve),
      },
      {
        path: "deep",
        name: "deep",
        meta: {
          title: "CCE Cash",
          allowBack: false,
        },
        component: (resolve) =>
          require(["./pages/main/home/deep.vue"], resolve),
      },
      {
        path: "order",
        name: "order",
        meta: {
          title: "CCE Cash",
          allowBack: false,
        },
        component: (resolve) =>
          require(["./pages/main/home/order.vue"], resolve),
      },
      {
        path: "orderdeep",
        name: "orderdeep",
        meta: {
          title: "CCE Cash",
          allowBack: false,
        },
        component: (resolve) =>
          require(["./pages/main/home/orderdeep.vue"], resolve),
      },
      {
        path: "support",
        name: "support",
        meta: {
          title: "CCE Cash",
          allowBack: false,
        },
        component: (resolve) =>
          require(["./pages/main/home/support.vue"], resolve),
      },
      {
        path: "readPage",
        name: "readPage",
        meta: {
          title: "CCE Cash",
          allowBack: false,
        },
        component: (resolve) =>
          require(["./pages/main/home/readPage.vue"], resolve),
      },
    ],
  },
  { path: "/*", name: "nofound", component: Nofound },
];
// 路由配置
const RouterConfig = {
  routes: localRouters,
};

// 以下push方法重写是为了处理NavagationDuplicated问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
export const router = new VueRouter(RouterConfig);
