<template>
  <header id="header" class="ontop">
    <div class="wrapper">
      <!-- 移动端样式 -->
      <div
        class="left-menu-wrap"
        id="left_menu_wrap"
        :class="{
          active: isShowLeftMenu,
        }
        "
      >
        <div id="left_menu_btn" @click="isShowLeftMenu = !isShowLeftMenu">
          <!-- <img src="/img/menu.svg" alt=""  @click="isShowLeftMenu = !isShowLeftMenu"> -->
          <div><span></span><span></span><span></span></div>
          <!-- <img id="logoImage" src="/img/logo@1x.svg"> -->
        </div>

        <nav class="left-menu" style="background: #2a457e" id="left_menu">
          <section>
            <ul id="left_menu_content" class="menu linkhl">
              <li class="menu-head"><span>CCE Cash</span></li>
              <li>
                <a
                  :class="myPath == '#/main/home' ? 'active' : ''"
                  href="/#/main/home"
                  >{{ $t("menus.Home") }}</a
                >
              </li>
              <!-- <li> -->
                <!-- <a -->
                  <!-- href="/#/main/deep" -->
                  <!-- :class="myPath == '#/main/deep' ? 'active' : ''" -->
                <!-- > -->
                  <!-- {{ $t("menus.Deep") }}</a -->
                <!-- > -->
              <!-- </li> -->
              <li>
                <a
                  :class="
                    myPath == '#/main/readPage?page=about' ? 'active' : ''
                  "
                  href="/#/main/readPage?page=about"
                  >{{ $t("menus.About") }}</a
                >
              </li>

              <li>
                <a
                  :class="myPath == '#/main/readPage?page=faq' ? 'active' : ''"
                  href="/#/main/readPage?page=faq"
                  >{{ $t("menus.FAQ") }}</a
                >
              </li>

              <li>
                <a
                  :class="myPath == '#/main/support' ? 'active' : ''"
                  href="/#/main/support"
                  >{{ $t("menus.Support") }}</a
                >
              </li>

              <li></li>
              <li></li>
              <!-- 移动端导航切换 -->
              <!-- <div class="loc hoverhl">
                <a class="menu-focus-btn" tabindex="0">
                  <div class="ui-select-value my-loc">
                    <img alt="icon" :src="langs[langIndex].icon" />
                    <span style="margin-left: 5px">{{
                      langs[langIndex].name
                    }}</span>
                  </div>
                </a>
                <ul
                  class="menu-focus-opened hoverhl"
                  style="background: #233a6a; border-style: none"
                >
                  <li
                    @click="changeLang(item.lang, index)"
                    v-for="(item, index) in langs"
                    :key="item.name"
                  >
                    <a
                      style="display: flex; align-items: center"
                      href="javascript:void(0)"
                      ><span style="color: white">{{ item.name }}</span>
                    </a>
                  </li>
                </ul>
                <div class="shadow-body" tabindex="0"></div>
              </div> -->
            </ul>
          </section>
        </nav>
      </div>

      <!-- pc 端 导航栏样式 -->
      <nav class="clrfix my-header">
        <!-- logo -->
        <a href="/#/" id="logo" class="logo">
          <img id="logoImage" src="/img/logo@1x.svg" width="130px" />
        </a>
        <div>
        <!-- 语言切换 -->
        <div class="loc hoverhl">
          <a class="menu-focus-btn" tabindex="0">
            <div class="ui-select-value my-loc">
              <img alt="icon" :src="langs[langIndex].icon" />
              <span style="margin-left: 5px">{{ langs[langIndex].name }}</span>
            </div>
          </a>
          <ul
            class="menu-focus-opened hoverhl"
            style="background: #233a6a; border-style: none"
          >
            <li
              @click="changeLang(item.lang, index)"
              v-for="(item, index) in langs"
              :key="item.name"
            >
              <a
                style="display: flex; align-items: center"
                href="javascript:void(0)"
                ><span style="color: white" class="phone-span">{{ item.name }}</span>
              </a>
            </li>
          </ul>
          <div class="shadow-body" tabindex="0"></div>
        </div>
        <!-- 导航栏 -->
        <ul class="nav menu hoverhl my-link">
          <li>
            <a
              :class="myPath == '#/main/home' ? 'active' : ''"
              href="/#/main/home"
              >{{ $t("menus.Home") }}</a
            >
          </li>
          <!-- <li> -->
            <!-- <a -->
              <!-- :class="myPath == '#/main/deep' ? 'active' : ''" -->
              <!-- href="/#/main/deep" -->
              <!-- >{{ $t("menus.Deep") }}</a -->
            <!-- > -->
          <!-- </li> -->
          <li>
            <a
              :class="myPath == '#/main/readPage?page=about' ? 'active' : ''"
              href="/#/main/readPage?page=about"
              >{{ $t("menus.About") }}</a
            >
          </li>
          <li>
            <a
              :class="myPath == '#/main/readPage?page=faq' ? 'active' : ''"
              href="/#/main/readPage?page=faq"
              >{{ $t("menus.FAQ") }}</a
            >
          </li>
          <li>
            <a
              :class="myPath == '#/main/support' ? 'active' : ''"
              href="/#/main/support"
              >{{ $t("menus.Support") }}</a
            >
          </li>
        </ul>


        </div>
      </nav>

      <!-- 暂时屏蔽 -->
      <div class="shadow-body"></div>
    </div>
  </header>
</template>

<script>
import cookies from "vue-cookies";

export default {
  name: "headerPgae",
  data() {
    return {
      langs: [
        {
          name: "English",
          icon: "/img/en.svg",
          lang: "en-US",
        },
        {
          name: "繁体中文",
          icon: "/img/zh.svg",
          lang: "zh",
        },
        // {
        //   name: "Русский",
        //   icon: "loc-ru",
        //   lang: "ru",
        // },
      ],
      langIndex: cookies.get("langIndex") ? cookies.get("langIndex") : 0,
      isShowLeftMenu: false,
      myPath: "",
    };
  },

  mounted() {
    this.myPath = window.location.hash;
    console.log(window.location, 2222222);
    console.log(this.myPath, "------");
  },
  watch: {
    "$route.query.page"(newValue, oldValue) {
      this.myPath = window.location.hash;
      this.isShowLeftMenu=false
      // console.log(this.isShowLeftMenu);q w+q  ASSSADASDASDASDSADZ this.isShowLeftMenu0000Q
      console.log("参数变化了", newValue, oldValue);
    },
  },
  methods: {
    changeLang(lang, index) {
      this.$i18n.locale = lang;
      this.langIndex = index;
      this.$cookies.set("lang", lang);
      this.$cookies.set("langIndex", index);
      this.$cookies.remove("notice_ts");
      this.$router.push({
        query: {
          ...this.$route.query,
          lang,
        },
      });
    },
  },
};
</script>

<syle lang="less">
@import url("../assets/css/header.less");
nav.left-menu li.menu-head > span {
  font-family: Bebas;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.82px;
  color: #ffffff;
  margin-top: 0;
}
nav.left-menu li > a,
nav.left-menu li > span {
  font-family: Bebas;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.82px;
  color: #ffffff !important;
  margin-top: 32px;
  margin-left: 47px;
}
nav.left-menu ul {
  border-top: 0px;
  .menu-head {
    // border: 1px solid red;
    > span {
      color: white !important;
    }
  }
}

ul.nav.menu li {
  a {
    // border: 1px solid red;
    font-family: Bebas !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.17px;
    text-align: left;
  }
}
.menu-focus-opened > li {
    border: 2px solid transparent;
    overflow: hidden;
    border-radius: 6px;
    height: 34px;
  &:hover {
    background: #4790f6;
  }

}
.menu-focus-opened > li >a > span {
  height: 34px;
  line-height: 30px;
}
.menu-focus-opened {
  width: 112px;
  height: 72px;
  padding: 2px;
}
.loc .menu-focus-opened a {
    height: 34px;
    line-height: 36px !important;
  }
  .loc .menu-focus-opened {
    min-width:initial;
    right: 9px;
  width: 112px;
    top: 95%;
  }
a,
.hl,
.hoverhl:hover,
.linkhl a:hover {
  color: #4790f6;
}
// .active {
//     color: #4790f6 !important;
//   }
@media only screen and (max-width: 700px) {
  .phone-span{
     height: 35px;
     line-height: 35px;
  }
  .menu-focus-btn{
    cursor: initial;
  }

  header .logo {
    display: inline-block;
    /* float: none; */
    margin: 0 auto;
    text-align: center;
    padding: 1.5em 0;
    position: relative;
    z-index: 10000;
    margin-left: 10px;
  }

  #left_menu_btn > div > span {
    background-color: #4790f6 !important;
    height: 2px;
    margin-bottom: 5px;
  }
  header .logo > img {
    width: 101px !important;
    margin-left: 12px;
    margin-top: -8px;
  }
  .left-menu-wrap {
    left: -7px;
  }
  .loc {
    right: 13px;
    top: -22px;
  }
  .menu-focus-btn {
    display: initial;
  }
  .loc .menu-focus-opened a {
    height: 34px;
    line-height: 34px;
  }
  .loc .menu-focus-opened {
    width: 112px;
    right: -3px;
    top: 117%;
  }
  .loc .ui-select-value > img {
    margin-left: 10px;
  }
  .loc .ui-select-value > span {
    margin-left: 8px !important ;
    opacity: 1;
    font-size: 12px;
    visibility: inherit;
  }
}
.my-header .my-loc {
  background-color: #4790f6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 102px;
  height: 28px !important;
  top: 18px;
  background: linear-gradient(270deg, #4775f6 0%, #2957d0 100%);
  border: 0.5px solid #576cf2;
  box-shadow: 0px 0px 12px 0px #1d72e767;
  border-radius: 20px;
  font-family: Bebas;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.88px;
}
.active > nav.left-menu {
  width: 256px;
}
@media screen and (min-width: 900px) {
  .container .box-container .logo-container {
    width: 640px !important ;
  }
}

@media screen and (max-width:740px) {
  #header {
    background: url("/img/header-bg.png") no-repeat;
    background-position: center;
    background-size: cover;
  }
}
</syle>
