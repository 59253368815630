/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from "axios";
import store from "../store";
import { Message } from "element-ui";
import { router } from "../router";

axios.defaults.withCredentials = true;
/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = (msg) => {
  Message({
    message: msg,
    duration: 1000,
    forbidClick: true,
  });
};

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  router.replace({
    path: "/login",
    query: {
      redirect: router.currentRoute.fullPath,
    },
  });
};

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, info) => {
  // 状态码判断
  switch (status) {
    // 401: 未登录状态，跳转登录页
    // 403 token过期
    // 清除token并跳转登录页
    case 401:
      tip("登录过期，请重新登录");
      sessionStorage.removeItem("token");
      setTimeout(() => {
        toLogin();
      }, 1000);
      break;
    case 403:
      tip("权限不足");
      break;
    // 404请求不存在
    case 404:
      tip("请求的资源不存在");
      break;
    default:
      Message.error(info);
  }
};

// 创建axios实例
// http://222.212.89.53:57080   //公网地址
// http://10.40.116.8:9801      //家富的IP
// http://172.27.16.4:9801      //公司线网地址
// https://cc.peopleshare.cn/cc-console-api      //5G服务器线网地址
// http://129.195.120.131:9801/cc-console-api    //南通线网地址
// http://139.155.51.62:19801/cc-console-api
var Axios = axios.create({
  timeout: 1000 * 600,
  baseURL: process.env.VUE_APP_BASEURL,
  withCredentials: true,
});
// Axios.defaults.withCredentials = true;
// 设置post请求头
//Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// Axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
/**
 *
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
Axios.interceptors.request.use(
  (config) => {
    let token = sessionStorage.getItem("token")
      ? sessionStorage.getItem("token")
      : "";
    // let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJBUFAiLCJwaG9uZSI6IjE3NzExNDMzMDIzIiwiaXNzIjoiU2VydmljZSIsImlkIjoxMzY1MTkwODcyMDkzNzE2NDgyLCJleHAiOjE2MTc0MzE5MjgsImlhdCI6MTYxNjU2NzkyOH0.prVc4wNCPqM7vAJ_u3Ob1oyhRiESiz2E6LCyP7-S_pQ'
    if (token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.authorization = token;
    }
    return config;
  },
  (err) => {
    Message.error("网络不给力，请稍后再试");
    return Promise.reject(err);
  }
);

// 响应拦截器
Axios.interceptors.response.use(
  // 请求成功
  (res) => {
    if (res.status === 200) {
      if (res.data.code == 401) {
        Message.error("您暂无当前请求权限");
        store.commit("clearCookie");
        setTimeout(() => {
          toLogin();
        }, 2000);
      } else {
        return res;
      }
    } else {
      Promise.reject(res);
    }
  },
  // 请求失败
  (error) => {
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.error);
      return Promise.reject(response);
    } else {
      console.log(error, "错误信息");
      Message.error("未知异常，请联系管理员！");
      // toLogin();
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      //			store.commit('changeNetwork', false);
    }
  }
);
/* 统一封装get请求 */
export const get = (url, params, config) => {
  params = params || {};
  return new Promise((resolve) => {
    Axios.get(url, {
      params,
    })
      .then((res) => {
        if (res.data.code === 0) {
          resolve(res.data);
        } else {
          Message.error("Error! Please try again.");
          reject();
        }
      })
      .catch((err) => {
        // Message.error('服务器出错，请联系管理员！');
        //  reject(err)
      });
  });
};

/* 统一封装post请求  */
export const post = (url, data, headers, isFile = false, config = {}) => {
  data = data || {};
  var formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }

  headers = headers || {
    "Content-Type": "application/json; charset=utf-8",
  };
  return new Promise((resolve, reject) => {
    Axios.post(url, formData, {
      headers: headers,
      ...config,
    })
      .then((res) => {
        if (res.headers.authorization) {
          sessionStorage.setItem(
            "token",
            JSON.stringify(res.headers.authorization)
          );
        }
        if (res.data.code === 0 || isFile) {
          resolve(res.data);
        } else {
          Message.error("Error! Please try again.");
          reject();
        }
      })
      .catch((err) => {
        // Message.error('服务器出错，请联系管理员！');
        reject();
      });
  });
};
export const Instance = Axios;
