import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import {
  Message,
  MessageBox
}
from 'element-ui'
import Util from './plugins/util';
import store from './store';
import * as echarts from 'echarts';
import * as api from "@/plugins/api";
import {
  router
} from "./router";

// 国际化
import VueI18n from 'vue-i18n'
import messages from './plugins/lang'

// 注册v-xx命令
import './plugins/command/v-copy'
import header from './components/header'
import VModal from 'vue-js-modal'
import VueCookies from "vue-cookies";

Vue.use(VModal, { dialog: true });
Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(ElementUI);
Vue.use(VueCookies);

import vSelect from 'vue-select'

Vue.component('v-select', vSelect);

import 'vue-select/dist/vue-select.css';

let lang = VueCookies.get('lang') ? VueCookies.get('lang')  : 'en-US';
if (!VueCookies.get('lang')) {
	let ulang = Util.getUrlKey('lang');
	let langs = header.data().langs;
	if (ulang){
		for(var key in langs){
			if (langs[key].lang == ulang){
				VueCookies.set('lang', ulang);
				VueCookies.set('langIndex', key);
				lang = ulang;
				break;
			}
		}
	}else{
        VueCookies.set('lang', 'en-US');
        VueCookies.set('langIndex', 0);
	}
}


// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  locale: lang, // 设置地区
  messages, // 设置地区信息
})

function scrollToTop() {  
  document.getElementById("app").scrollIntoView({
    behavior: "smooth",
  });
}

//实现全局路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }

  if(to.path != from.path){
    scrollToTop();
  }
  
  next();
});


Vue.config.productionTip = false;
Vue.prototype.$Util = Util;
Vue.prototype.$Echarts = echarts;
Vue.prototype.$Message = Message;
Vue.prototype.$store = store;
Vue.prototype.$api = api;
Vue.prototype.$scrollToTop = scrollToTop;
Vue.prototype.$confirm = MessageBox.confirm;




new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  data: {
    Hub: new Vue()
  }
}).$mount('#app')